.navbar {
  /* background-color: #5498ff; */
  padding: 0px 10px;
  /* border-radius: 5%; */
  border-right: 1px solid gray;
  /* min-height: 94vh; */
  position: relative;
}
.col {
  height: 94vh;
  /* height: 100%; */
}
/* .container { */
/* height: 100vh; */
/* overflow: hidden; */
/* } */

.nav-profile {
  text-align: right;
}
.arrow {
  position: absolute;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid black;
  border-radius: 10%;
  color: white;
  font-size: 1.2rem;
}
.arrow.open {
  background-color: black;
}
