.order {
	padding: 2.5px;
	border-bottom: 1px solid lightgray;
}
.order > * {
	padding: 0.5px 0px;
}
.order .active {
	color: orange;
}
.order .delivering {
	color: green;
}
.order .arrived {
	color: green;
}
.centered {
	text-align: center;
	margin: auto;
}
.danger {
	color: red;
}
.actions {
	text-align: right;
}
.for-later{
	background-color: blue;
	color: white;
	padding: 2px;
	border-radius: 25%;
}